<template>
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.9999 0C15.7013 0 0 15.7013 0 34.9999C0 54.2985 15.7013 69.9999 34.9999 69.9999C54.2985 69.9999 69.9999 54.2985 69.9999 34.9999C69.9999 15.7013 54.2987 0 34.9999 0ZM34.9999 66.9564C17.3782 66.9564 3.04343 52.6217 3.04343 34.9999C3.04343 17.3782 17.3782 3.04343 34.9999 3.04343C52.6217 3.04343 66.9564 17.3782 66.9564 34.9999C66.9564 52.6217 52.6217 66.9564 34.9999 66.9564Z"
    />
    <path
      d="M68.4783 33.4783H65.4349C64.5949 33.4783 63.9131 34.1601 63.9131 35.0001C63.9131 35.8401 64.5949 36.5217 65.4349 36.5217H68.4783C69.3183 36.5217 70.0001 35.8399 70.0001 34.9999C70.0001 34.1599 69.3183 33.4783 68.4783 33.4783Z"
    />
    <path
      d="M34.9998 0C34.1598 0 33.478 0.681786 33.478 1.52179V4.56521C33.478 5.40521 34.1598 6.087 34.9998 6.087C35.8398 6.087 36.5216 5.40521 36.5216 4.56521V1.52179C36.5216 0.681786 35.8398 0 34.9998 0Z"
    />
    <path
      d="M4.56521 33.4783H1.52179C0.681786 33.4783 0 34.1599 0 34.9999C0 35.8399 0.681786 36.5217 1.52179 36.5217H4.56521C5.40521 36.5217 6.087 35.8399 6.087 34.9999C6.087 34.1599 5.40521 33.4783 4.56521 33.4783Z"
    />
    <path
      d="M34.9998 63.9131C34.1598 63.9131 33.478 64.5949 33.478 65.4349V68.4783C33.478 69.3183 34.1598 70.0001 34.9998 70.0001C35.8398 70.0001 36.5216 69.3183 36.5216 68.4783V65.4349C36.5216 64.5949 35.8398 63.9131 34.9998 63.9131Z"
    />
    <path
      d="M35.4346 14C36.2747 14 36.9565 14.6818 36.9565 15.5218V33.7826C36.9565 34.6226 36.2747 35.3044 35.4346 35.3044C34.5946 35.3044 33.9127 34.6226 33.9127 33.7826V15.5218C33.9127 14.6818 34.5946 14 35.4346 14Z"
    />
    <path
      d="M35.4344 32.261C37.9516 32.261 40 34.3092 40 36.8262C40 39.3432 37.9516 41.3914 35.4344 41.3914C32.9171 41.3914 30.8687 39.3432 30.8687 36.8262C30.8687 34.3093 32.9171 32.261 35.4344 32.261ZM35.4344 38.348C36.2744 38.348 36.9563 37.6632 36.9563 36.8262C36.9563 35.9892 36.2744 35.3044 35.4344 35.3044C34.5943 35.3044 33.9124 35.9893 33.9124 36.8262C33.9124 37.6632 34.5943 38.348 35.4344 38.348Z"
    />
    <path
      d="M22.1852 47.9256L31.3165 38.7952C31.91 38.2017 32.8749 38.2017 33.4684 38.7952C34.062 39.3887 34.062 40.3535 33.4684 40.947L24.3372 50.0774C24.0388 50.3726 23.6493 50.5218 23.2597 50.5218C22.8701 50.5218 22.4805 50.3727 22.1852 50.0774C21.5917 49.484 21.5917 48.5191 22.1852 47.9256Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DriverTakeSlot',
}
</script>
